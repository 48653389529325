<template>
  <div>
    <el-form ref="form" :rules="rules" :model="form" label-width="80px">
      <el-form-item label="方块宽" prop="width" class="custom-form-item">
        <el-input-number v-model="form.width" controls-position="right" style="width: 100%;" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item label="方块高" prop="height" class="custom-form-item">
        <el-input-number v-model="form.height" controls-position="right" style="width: 100%;" :min="1">
        </el-input-number>
      </el-form-item>
      <el-form-item label="字体大小" prop="size" class="custom-form-item">
        <el-input-number v-model="form.size" controls-position="right" style="width: 100%;" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item label="方块边距" prop="margin" class="custom-form-item">
        <el-input-number v-model="form.margin" controls-position="right" style="width: 100%;" :min="1">
        </el-input-number>
      </el-form-item>
    </el-form>
    <div class="bottom-btn">
      <el-button type="primary" @click="onFormSubmit('form')">确 定</el-button>
      <el-button @click="closeDialog">关 闭</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          width: 50,
          height: 20,
          size: 11,
          margin: 10
        },
        rules: {
          width: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          height: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          size: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          margin: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }]
        }
      }
    },
    created() {
      let wareConfig = this.$cookies.get(this.$cache.WARE_CONFIG)
      if (wareConfig) {
        this.form= JSON.parse(wareConfig)
      }
    },
    methods: {
      onFormSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$cookies.set(this.$cache.WARE_CONFIG, this.form)
            this.$emit("confirm", this.form)
            this.$emit("close", {})
          } else {
            return false;
          }
        });
      },
      //关闭
      closeDialog() {
        this.$refs['form'].resetFields();
        this.$emit("close", {})
      }
    }
  }
</script>

<style>
</style>
